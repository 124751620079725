import React, { memo, useState } from "react"
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  IconButton,
  Stack,
  Box,
} from "@chakra-ui/react"
import { BiShow, BiHide } from "react-icons/bi"
import { useMaintenance } from "@app/hooks/useMaintenance"

type Props = {
  location: any
}

const MaintenanceForm: React.FC<Props> = ({ location }: any) => {
  const { handleSubmit, handleChange, data, error, loading } = useMaintenance(location)
  const [show, setShow] = useState(false)

  return (
    <Box as="form" onSubmit={handleSubmit} mb={8}>
      <Stack spacing={5}>
        <FormControl id="password" value={data?.password} onChange={handleChange} isRequired isInvalid={error}>
          <FormLabel>Password</FormLabel>
          <InputGroup size="md">
            <Input name="password" type={show ? "text" : "password"} />
            <InputRightElement>
              <IconButton
                variant="ghost"
                onClick={() => setShow(!show)}
                aria-label={`${show ? "Show" : "Hide"} password`}
                size="md"
                icon={show ? <BiHide /> : <BiShow />}
              />
            </InputRightElement>
          </InputGroup>
          {error && <FormErrorMessage>Invalid password</FormErrorMessage>}
        </FormControl>

        <Button type="submit" isDisabled={loading} isLoading={loading}>
          Login
        </Button>
      </Stack>
    </Box>
  )
}

export default memo(MaintenanceForm)
