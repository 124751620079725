import React, { memo } from "react"
import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react"

import MaintenanceForm from "./MaintenanceForm"
import { useImage } from "@app/hooks/useImage"
import Main from "@app/components/Main"
import Brand from "@app/components/Brand"
import type { Props } from "@app/pages/password"
import type { PageProps } from "@root/types/global"
import Global from "@app/theme/foundations/global"

const Maintenance: React.FC<PageProps<Props>> = ({ page, location }) => {
  const { title, content, image, backgroundColour } = page ?? {}
  const { getGatsbyImage } = useImage()
  const responsiveImage = image?.asset ? getGatsbyImage(image, { maxWidth: 1000 }) : false

  return (
    <>
      <Global />
      <Main>
        <Flex minH="100vh" direction={{ base: "column", md: "row" }}>
          <Box
            bgColor={backgroundColour?.hex || "white"}
            flex={{ md: 1 }}
            minH={{ base: 300, md: "unset" }}
            maxH={{ base: 600, md: "unset" }}
          >
            {image && <Image display="inline" fit="cover" w={"100%"} minH={300} h={"100%"} {...responsiveImage} />}
          </Box>
          <Box px={{ base: "6", md: "10", lg: "16", xl: "28" }} py={{ base: "6", md: "64" }}>
            <Box maxW="xl">
              <Box>
                <Box mb="16">
                  <Brand />
                </Box>
                <Heading as="h1" fontSize={{ base: "xl", md: "4xl" }}>
                  {title}
                </Heading>
                <Text mt="3" fontSize={{ base: "xl", md: "2xl" }}>
                  {content && content}
                </Text>
              </Box>
              <Box mt="10" maxW="80">
                <MaintenanceForm location={location} />
              </Box>
            </Box>
          </Box>
        </Flex>
      </Main>
    </>
  )
}

export default memo(Maintenance)
